import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },

  root: {
    flexGrow: 1,
  },

  floatContainer: {
    padding: 20,
    border: 'dashed black', 
  },

  floatChild: {
    float: 'left',
    margin: 20,
  },

  button: {
    backgroundColor: '#5bc0de',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
    padding: '10px 30px',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#4fa4bd',
      color: 'white'
    }
  },

  customFileUpload: {
    border: '1px solid #ccc',
    display: 'inline-block',
    color: 'white',
    backgroundColor: '#5bc0de',
    borderRadius: '15px',
    padding: '6px 12px',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#4fa4bd',
      color: 'white'
    },
    ['@media (max-width:960px)']: { 
      fontSize: '0px',
    }
  },


}));