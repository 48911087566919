import React from 'react';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaList, FaHeart } from 'react-icons/fa';
import { BiStore } from 'react-icons/bi';
import { ImNewspaper } from 'react-icons/im';
import { FiSettings, FiHome } from 'react-icons/fi';
import { RiPagesLine } from 'react-icons/ri';
import { MdOutlineSpaceDashboard, MdOutlineWallpaper, MdOutlineAttachMoney, MdOutlineContactPhone } from 'react-icons/md';
import { BsFileEarmarkPerson, BsHandbagFill } from 'react-icons/bs';
import { GrWorkshop } from 'react-icons/gr';
import { GiPortrait, GiClothes } from 'react-icons/gi';
import { IconButton } from '@material-ui/core';

const Sidebar = ({ collapsed, toggled, handleToggleSidebar }) => {
  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader><a href="/" style={{textDecoration: 'none', color: 'black', cursor: 'pointer'}}>
        <div
          style={{
            marginTop: '70px',
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'center'
          }}
        > 
          
            <img src='https://res.cloudinary.com/dchk5msou/image/upload/v1647541041/Slider/Mobile/5_cvt1lc.jpg' height={50} style={{borderRadius: '50%', marginRight: '10px'}} />
            <br/>
            administrator
        </div>
        </a>
      </SidebarHeader>

      <SidebarContent>
        {/* UP-MENU */}
        <Menu iconShape="circle">
          {/* NEWS */}
          <MenuItem
            icon={<MdOutlineSpaceDashboard size={20}/>}
            suffix={<span className="badge red">New</span>}
          >
            Dashboard
            <Link to='/dashboard'/>
          </MenuItem>
          {/* STORE */}
          <MenuItem icon={<BiStore size={20}/>}>
            <a href="https://dashboard.chec.io/products/" target="_blank">
              Store
            </a>
          </MenuItem>
          {/* PAGES */}
          <SubMenu
            suffix={<span className="badge yellow">4</span>}
            title="Pages"
            icon={<RiPagesLine size={20}/>}
          >
            <MenuItem icon={<FiHome size={20}/>} >
              Home
              <Link to='/home'/>
            </MenuItem>

            <MenuItem icon={<ImNewspaper size={20}/>} suffix={<span className="badge red">New</span>}>
              News
              <Link to='/news'/>
            </MenuItem>

            <MenuItem icon={<BsFileEarmarkPerson size={20}/>}>Biography</MenuItem>
            <MenuItem icon={<MdOutlineWallpaper size={20}/>}>Exhibitions</MenuItem>
            <SubMenu icon={<GrWorkshop size={20}/>} title="Projects">
                <MenuItem icon={<GiPortrait size={20}/>}>Portrait </MenuItem>
                <MenuItem icon={<GiClothes size={20}/>}>Fashion </MenuItem>
                <MenuItem icon={<BsHandbagFill size={20}/>}>Product </MenuItem>
            </SubMenu>
            <MenuItem icon={<MdOutlineAttachMoney size={20}/>}>Services</MenuItem>
            <MenuItem icon={<MdOutlineContactPhone size={20}/>}>Contact</MenuItem>
          </SubMenu>

        </Menu>

        <Menu iconShape="circle">
          {/* SETTINGS */}
          <MenuItem icon={<FiSettings size={20}/>}> Settings </MenuItem>

        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://silviupreda.art/"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              silviupreda.art
            </span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;