import React from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'

import useStyles from './styles';
import { FaBars } from 'react-icons/fa';
import { RiLogoutBoxRLine } from 'react-icons/ri';

const Navbar = ({ handleToggleSidebar }) => {
    const classes = useStyles();

    function singOut() {
        localStorage.removeItem('token');
        window.location.reload(false);
    }

    return (
        <>
           <AppBar position='fixed' className={classes.appBar} color="inherit">
                <Toolbar>
                    <div className="btn-toggle" onClick={() => handleToggleSidebar()}>
                        <FaBars/>
                    </div>
                    <Typography component={Link} to="/" variant='h6' className={classes.title} color="inherit">
                        <img src="" alt="" height='25px' className={classes.image} />
                        Silviu Preda | Admin Panel
                    </Typography>
                    <div className={classes.grow}/>
                    { window.location.pathname === '/' && (
                        <div className={classes.button}>
                            <IconButton onClick={singOut} aria-label="Sign Out" color="inherit">
                                <RiLogoutBoxRLine />
                            </IconButton>
                        </div>
                    )}
                </Toolbar>
           </AppBar>
        </>
    )
}

export default Navbar
