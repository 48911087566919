import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import jwt from 'jsonwebtoken'
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginPage = () => {

	const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Register user function - Only for creating admin account
    // async function registerUser(event) {
	// 	event.preventDefault()

	// 	const response = await fetch('http://localhost:5000/api/register', {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 		body: JSON.stringify({
	// 			username,
	// 			password,
	// 		}),
	// 	})

	// 	const data = await response.json()

	// 	if (data.status === 'ok') {
	// 		navigate('/login')
	// 	}
	// }


    async function loginAdmin(event) {
		event.preventDefault()

		const response = await fetch('https://silviupreda.herokuapp.com/api/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username,
				password,
			}),
		})

		const data = await response.json()

		if (data.user) {
			localStorage.setItem('token', data.user)
			window.location.href = '/'
		} else {
			alert('Please check your username and password')
		}
	}

    useEffect(() => {
		const token = localStorage.getItem('token')
		if (token) {
			const user = jwt.decode(token)
			if (!user) {
				localStorage.removeItem('token')
			} else {
				navigate('/')
			}
		}
	}, [])

    return (
        <>
            <Container>
                <h1 className="shadow-sm text-info mt-5 p-3 text-center rounded">Admin Panel</h1>
                <Row className="mt-5">
                    <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                        <Form onSubmit={loginAdmin}>
                            <Form.Group controlId="formBasicEmail" className='text-info fw-bold'>
                                <Form.Label>Username</Form.Label>
                                <Form.Control 
                                    type="username" 
                                    placeholder="Enter username" 
                                    onChange={(e) => setUsername(e.target.value)}
                                    value={username}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className='text-info fw-bold'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}/>
                            </Form.Group>

                            <Button variant="col-md-1 mt-5 btn btn-info text-white fw-bold w-100" type="submit">
                                Login
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <h6 className="mt-5 p-3 text-center text-secondary ">*Any unauthorized access is strictly prohibited.</h6>
                <h6 className="text-center text-secondary">Copyright © 2022 Silviu Preda. All Rights Reserved.</h6>
            </Container>
        </>
    );
};

export default LoginPage;