import React, { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'
import { useNavigate } from 'react-router-dom'
import Loading from '../Loading/Loading'
import useStyles from './styles';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Dashboard = ( { setSidebarVisibility, setNavbarVisibility } ) => {
	const navigate = useNavigate()
	const [username, setUsername] = useState('')
    const [done, setDone] = useState(undefined);

	const sliderMobileImages = [
		{
			id: '1',
			url: 'https://res.cloudinary.com/dchk5msou/image/upload/v1647541041/Slider/Mobile/1_ysbym5.jpg'
		},
		{
			id: '2',
			url: 'https://res.cloudinary.com/dchk5msou/image/upload/v1647541041/Slider/Mobile/2_lr1fno.jpg'
		},
		{
			id: '3',
			url: 'https://res.cloudinary.com/dchk5msou/image/upload/v1647541041/Slider/Mobile/3_fiztaf.jpg'
		},
		{
			id: '4',
			url: 'https://res.cloudinary.com/dchk5msou/image/upload/v1647541041/Slider/Mobile/4_sgpghh.jpg'
		},
		{
			id: '5',
			url: 'https://res.cloudinary.com/dchk5msou/image/upload/v1647541041/Slider/Mobile/5_cvt1lc.jpg'
		}
	]

	const [sliderDesktopImages, setSliderDesktopImages] = useState([
		'https://res.cloudinary.com/dchk5msou/image/upload/v1647447322/Slider/Desktop/185585728_3359607624142003_6571967556333109225_n_zcnxtt.jpg',
		'https://res.cloudinary.com/dchk5msou/image/upload/v1647540914/Slider/Desktop/245390211_3791047117664716_3747142854797531758_n_ckonko.jpg'
	]);

	const [mobileImages, updateMobileImages] = useState(sliderMobileImages);

	function handleOnDragEnd(result) {
		if(!result.destination) return;
		console.log(result);
		const items = Array.from(mobileImages);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		updateMobileImages(items);
		// updateDatabase(items);
	} 

    const classes = useStyles();

	async function loadData() {
		console.log('Loading data..')
		const req = await fetch('https://silviupreda.herokuapp.com/api/user', {
			headers: {
				'x-access-token': localStorage.getItem('token'),
			},
		})

		const data = await req.json()
		if (data.status === 'ok') {
			setUsername(data.username)
            setDone(true);
			setSidebarVisibility(true);
			setNavbarVisibility(true);
		} else {
			alert(data.error)
		}
	}

	useEffect(() => {
		const token = localStorage.getItem('token')
		if (token) {
			const user = jwt.decode(token)
			if (!user) {
				localStorage.removeItem('token')
				navigate('/login')
			} else {
				loadData()
			}
		} else {
            navigate('/login')
        }
	}, [])

	function uploadImage() {
		loadData();
	}

	function fileSelected() {
		
	}

	return (
        <>
            {!done ? (
                <Loading />
            ) : (
                <main className={classes.content}>
					<div className={classes.toolbar} />
                    <h1>Your username: {username || 'No username found'}</h1>
					
					<div style={{margin:'20px 0px 10px'}}>
						<h4 style={{float:'left', position:'relative', top:'40%'}}> Carousel images for Mobile </h4>

						<form onSubmit={uploadImage} style={{float:'right'}}>
							<label className={classes.customFileUpload}>
									<input type="file" accept='image/*' style={{display: 'none'}}/>
									< FaCloudUploadAlt size={25}/> Upload images
							</label>
						</form>
					</div>

					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId='images' direction='horizontal'>
							{(provided) => (
								<div className={classes.floatContainer} {...provided.droppableProps} ref={provided.innerRef}>
									{mobileImages.map(({id, url}, index) => (
										<Draggable key={id} draggableId={id} index={index}>
											{(provided) => (
												<div className={classes.floatChild} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
													<img src={url} height={100} width={100} />
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					<div style={{margin:'20px 0px 10px'}}>
						<h4 style={{float:'left', position:'relative', top:'40%'}}> Carousel images for Desktop </h4>
						<form onSubmit={uploadImage} style={{float:'right'}}>
							<label className={classes.customFileUpload}>
									<input type="file" accept='image/*' style={{display: 'none'}}/>
									< FaCloudUploadAlt size={25}/> Upload images
							</label>
						</form>
					</div>
					<div className={classes.floatContainer}>
						{sliderDesktopImages.map((imageSrc) => (
							<div className={classes.floatChild}>
								<img src={imageSrc} height={100} width={160} />
							</div>
						))}
					</div>
                </main>
            )}
        </>

	)
}

export default Dashboard