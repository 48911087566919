import React, { useState, useCallback } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import './components/Sidebar/style.scss'
import LoginPage from './components/LoginPage/LoginPage';
import Dashboard from './components/Dashboard/Dashboard';
import Navbar from './components/Navbar/Navbar.jsx';
import Sidebar from './components/Sidebar/Sidebar.jsx';

function App() {

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [sidebarVisibility, setSidebarVisibility] = useState(false);
  const [navbarVisibility, setNavbarVisibility] = useState(false);

  const wrapperSetSidebarVisibility = useCallback(val => {
    setSidebarVisibility(val);
  }, [setSidebarVisibility]);

  const wrapperSetNavbarVisibility = useCallback(val => {
    setNavbarVisibility(val);
  }, [setNavbarVisibility]);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
      <HashRouter>

        {sidebarVisibility && <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        /> }
        { navbarVisibility && <Navbar handleToggleSidebar={handleToggleSidebar}/> }
        
        <Routes>
          <Route path='/' exact element={<Navigate replace to='/dashboard'/>} />
          <Route path='/login' exact element={<LoginPage/>} />
          <Route path='/dashboard' exact element={<Dashboard setSidebarVisibility={wrapperSetSidebarVisibility} setNavbarVisibility={wrapperSetNavbarVisibility}/>} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
