import React from 'react'
import ReactLoading from 'react-loading';

function Loading() {
  return (
    <div style={{
        position: 'absolute', 
        left: '50%', 
        top: '50%',
        transform: 'translate(-50%, -50%)',
    }}>
        <ReactLoading type='bars' color='#5bc0de' height={200} width={200} />
    </div>
  )
}

export default Loading